import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const HelpPage = () => {

  const {t} = useTranslation();

  return (
    <div className="w-container">
      <div className="w-section">
        <div className="w-container bookshelf_head">
          <h1 className="h1">{t("help.title")}</h1>
        </div>

        <h3>Добавление слов в личный словарь</h3>
        <p>
          Выберите текст, который хотите прочитать (послушать аудиокнигу и т.д.) в разделе <Link to="/">{t("app.navi.materials")}</Link> либо в раздле <Link to="/shelf">{t("app.navi.shelf")}</Link>.
          <br/>
          Внутри справочника все слова отсортированы в порядке убывания частоты их использования в тексте.
          Чтобы посмотреть словоформы нужно навести курсор на количество слов.
          <img src="/images/help/05.png" alt="" className="help"/>
        </p>
        <p>
          Обновите свой личный словарь:
        </p>
        <ul>
          <li>Выберите слова, которые вам незнакомы и вы хотите их изучить. Нажмите кнопку "Добавить на изучение".</li>
          <li>Затем выберите оставшиеся слова и нажми кнопку "Добавить в знакомые".</li>
          <li>Загрузите следующую партию слов и повторите процесс снова.</li>
        </ul>
        <p>
          Все обработанные слова попадут в ваш личный словарь и будут учитываться при обработке последующих текстов.
        </p>

        <h3>Добавление текстов</h3>
        <p>
          Вы можете загрузить свой собственный текст (субтитры и т.д.) в разделе <Link to="/shelf">{t("app.navi.shelf")}</Link>.
          Все добавленные тексты будут храниться здесь же.
        </p>

        <h3>Работа с личным словарем</h3>
        <p>
          Изменить перевод слова и внести другие изменения в ваш личный словарь можно в разделе <Link to="/dictionary">{t("app.navi.dictionary")}</Link>.
          Окно редактирования перевода открывается по клику на слове.
          <img src="/images/help/07.png" alt="" className="help"/>
        </p>
        <p>
          При изменениях в вашем личном словаре сервис вычисляет "Комфорт" (процент понимания текста с учетом всех слов в вашем личном словаре).
          <img src="/images/help/08.png" alt="" className="help"/>
          Мы рекомендуем приступать к освоению материала, когда это значение будет не менее 50%.
        </p>

        <h3>Тренировка</h3>
        <p>
          Тренировка незнакомых слов доступна как для вашего личного словаря в целом, так и для отдельно взятого текста.
          Для этого нажмите кнопку "Повторение" или "Аудирование" в соответствующем разделе.
          <img src="/images/help/09.png" alt="" className="help"/>
          Тренируя слова, вы можете пользоваться разными подсказками.
          <img src="/images/help/10.png" alt="" className="help"/>
          <img src="/images/help/11.png" alt="" className="help"/>
          <img src="/images/help/12.png" alt="" className="help"/>
        </p>
      </div>
    </div>
  );
}
